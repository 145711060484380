import React, { useState,useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./EditForm.css";
import { getProfile ,updateProfile} from '../Controller/Account';
import Editprofilepopmodel from "./Editprofilepopmodel";
function EditProfile() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState('');
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen); 
  };
 // console.log(adminInformation);
  const [formData, setFormData] = useState({
    admin_first_name: "",
    admin_middle_name: "",
    admin_last_name: "",
    admin_email: "",
    admin_phone: "",
    admin_address: "",
    admin_city: "",
    admin_state: "",
    admin_country: "",
    admin_pincode: "",
  });
/*************************************************************
 * Function Name  : getprofiledata
 * Purposs        : This function is used get data
 * Created Date   : 09-01-2024
 *************************************************************/
const getprofiledata = async () => {
  const options = {  };
  try {
    const result = await getProfile(options ,adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.userData;
      setFormData(responseData);
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};

  useEffect(() => {
    window.scrollTo(0, 0);
    getprofiledata();
  }, []);

  
  /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((preVal) => ({
      ...preVal,
      [name]: value,
    }));
    setErrors((preError) => ({
      ...preError,
      [name]: '', 
    }));
  };
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used update data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for email validation
    const phoneRegex = /^[1-9]\d{9}$/; // Regex for 10-digit phone validation
    const nameRegex = /^[a-zA-Z\s]+$/; 

      if (!formData.admin_first_name) {
        setErrors((preError) => ({
          ...preError,
          admin_first_name: 'First name is required.',
        }));
      } else if (formData.admin_first_name.length < 4) {
        setErrors((preError) => ({
          ...preError,
          admin_first_name: 'First name must be at least 4 characters long.',
        }));
      }else if (formData.admin_first_name.length > 30) {
        setErrors((preError) => ({
          ...preError,
          admin_first_name: 'First name must not exceed 30 characters.',
        }));
      }  else if (!nameRegex.test(formData.admin_first_name)) {
      setErrors((preError) => ({
        ...preError,
        admin_first_name: 'First name cannot contain special characters or numbers.',
      }));
    } else if (formData.admin_middle_name && formData.admin_middle_name.trim()) {
      if (!nameRegex.test(formData.admin_middle_name)) {
        setErrors((preError) => ({
          ...preError,
          admin_middle_name: 'Middle name cannot contain special characters or numbers.',
        }));
      } else if (formData.admin_middle_name.length < 4) {
        setErrors((preError) => ({
          ...preError,
          admin_middle_name: 'Middle name must be at least 4 characters long.',
        }));
      } else if (formData.admin_middle_name.length > 20) {
        setErrors((preError) => ({
          ...preError,
          admin_middle_name: 'Middle name must not exceed 20 characters.',
        }));
      }
    } else if (!formData.admin_last_name) {
      setErrors((preError) => ({
        ...preError,
        admin_last_name: 'Last name is required.',
      }));
    } else if (!nameRegex.test(formData.admin_last_name)) {
      setErrors((preError) => ({
        ...preError,
        admin_last_name: 'Last name cannot contain special characters or numbers.',
      }));
    } else if (formData.admin_last_name.length < 4) {
      setErrors((preError) => ({
        ...preError,
        admin_last_name: 'Last name must be at least 4 characters long.',
      }));
    }else if (formData.admin_last_name.length > 30) {
      setErrors((preError) => ({
        ...preError,
        admin_last_name: 'Last name must not exceed 30 characters.',
      }));
    } else if (!formData.admin_email) {
        setErrors((preError) => ({
          ...preError,
          admin_email: 'Email is required.',
        }));
      }else if (!emailRegex.test(formData.admin_email)) {
        setErrors((preError) => ({
          ...preError,
          admin_email: 'Invalid email format.',
        }));
      }else if (!formData.admin_phone) {
        setErrors((preError) => ({
          ...preError,
          admin_phone: 'Phone No is required.',
        }));
      }else if (!phoneRegex.test(formData.admin_phone)) {
        setErrors((preError) => ({
          ...preError,
          admin_phone: 'Phone No must be 10 digits and cannot start with 0.',
        }));
      } else if (!formData.admin_address) {
        setErrors((preError) => ({
          ...preError,
          admin_address: 'Address is required.',
        }));
      }else if (!formData.admin_city) {
        setErrors((preError) => ({
          ...preError,
          admin_city: 'City is required.',
        }));
      }else if (!formData.admin_state) {
        setErrors((preError) => ({
          ...preError,
          admin_state: 'State is required.',
        }));
      }else if (!formData.admin_country) {
        setErrors((preError) => ({
          ...preError,
          admin_country: 'Country is required.',
        }));
        }else if (!formData.admin_pincode) {
          setErrors((preError) => ({
            ...preError,
            admin_pincode: 'Pincode is required.',
          }));
      }else{
      const updateResult = await updateProfile(formData , adminInformation.token);
     
        if(updateResult.status === 200){
          const updatedAdminInformation = {
            ...adminInformation,
            admin_first_name: updateResult.data.response.result.admin_first_name,
            admin_middle_name: updateResult.data.response.result.admin_middle_name,
            admin_last_name: updateResult.data.response.result.admin_last_name,
            admin_email: updateResult.data.response.result.admin_email,
            admin_phone: updateResult.data.response.result.admin_phone,
            admin_address: updateResult.data.response.result.admin_address,
            admin_city: updateResult.data.response.result.admin_city,
            admin_state: updateResult.data.response.result.admin_state,
            admin_country: updateResult.data.response.result.admin_country,
            admin_pincode: updateResult.data.response.result.admin_pincode,
          };
  
          localStorage.setItem('ADMIN-INFO', JSON.stringify(updatedAdminInformation));
  
          togglePopUp();

        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Profile</h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <form onSubmit={handleSubmit}>
                      <div className="form-group  row">
                      <div className="col-md-3">
                        <label htmlFor="fullname">First Name<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_first_name"
                          name="admin_first_name"
                          value={formData.admin_first_name}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_first_name?(<p style={{color:"red"}}>{errors?.admin_first_name}</p>):''}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="fullname">Middle Name</label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_middle_name"
                          name="admin_middle_name"
                          value={formData.admin_middle_name}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_middle_name?(<p style={{color:"red"}}>{errors?.admin_middle_name}</p>):''}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="fullname">Last Name<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_last_name"
                          name="admin_last_name"
                          value={formData.admin_last_name}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_last_name?(<p style={{color:"red"}}>{errors?.admin_last_name}</p>):''}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="fullname">Email<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_email"
                          name="admin_email"
                          value={formData.admin_email}
                          onChange={handleInputChange}
                          readOnly
                        />
                        {errors?.admin_email?(<p style={{color:"red"}}>{errors?.admin_email}</p>):''}
                      </div>
                      </div>
                      <div className="form-group row">
                      <div className="col-md-4">
                        <label htmlFor="fullname">Phone No<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="number"
                          className="form-control common_category_input_for_all_section"
                          id="admin_phone"
                          name="admin_phone"
                          value={formData.admin_phone}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_phone?(<p style={{color:"red"}}>{errors?.admin_phone}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">Address<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_address"
                          address="admin_address"
                          value={formData.admin_address}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_address?(<p style={{color:"red"}}>{errors?.admin_address}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">City<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_city"
                          name="admin_city"
                          value={formData.admin_city}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_city?(<p style={{color:"red"}}>{errors?.admin_city}</p>):''}
                      </div>
                      </div>
                      <div className="form-group row">
                      <div className="col-md-4">
                        <label htmlFor="fullname">State<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_state"
                          name="admin_state"
                          value={formData.admin_state}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_state?(<p style={{color:"red"}}>{errors?.admin_state}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">Country<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_country"
                          name="admin_country"
                          value={formData.admin_country}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_country?(<p style={{color:"red"}}>{errors?.admin_country}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">Pincode<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="admin_pincode"
                          name="admin_pincode"
                          value={formData.admin_pincode}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_pincode?(<p style={{color:"red"}}>{errors?.admin_pincode}</p>):''}
                      </div>
                      </div>
                      <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#891621',borderColor:'#5f1e24' }}>
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Editprofilepopmodel popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
        </div>
      </div>
    </div>
  );
}

export default EditProfile;

import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest} from '../Controller/API';

/*********************************************************
 *  This function is use to login api
 *********************************************************/
export const login = async (options)=>{
    try{
        const {email, password}= options;
        if(!email){
            return {status : false, message:"Email is required"}
        } else if(!password){
            return {status : false, message:"Password is required"}
        } else{
            const posrData ={ 
                url : API_ADMIN_URL+'users/login',
                postData : options,
                headers: {
                    'key':'d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
                    'Content-Type': 'application/json',
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return {status : true, message:`Your One Time Password is sent to ${email}`}
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

/*********************************************************
 *  This function is use to login api
 *********************************************************/
export const verifyLoginOtp = async (options)=>{
    try{
        const {email, otp}= options;
        if(!email){
            return {status : false, message:"Email is required"}
        } else if(!otp){
            return {status : false, message:"One Time Password is required"}
        } else{
            const posrData ={ 
                url : API_ADMIN_URL+'users/verify-login-otp',
                postData : options,
                headers: {
                    'key':'d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
                    'Content-Type': 'application/json',
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                //sessionStorage.setItem('TOKEN', res.data.response.result.token);
               // sessionStorage.setItem('ADMIN-INFO', JSON.stringify(res.data.response.result));
               localStorage.setItem('TOKEN', res.data.response.result.token);
               localStorage.setItem('ADMIN-INFO', JSON.stringify(res.data.response.result));

                return {status : true, message:`Welcome Back! ${res.data.response.result.admin_first_name}`};
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        console.log(error);
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

/*********************************************************
 *  This function is use to forgotPassword api
 *********************************************************/
export const forgotPassword = async (options)=>{
    try{
        const {email}= options;
        if(!email){
            return {status : false, message:"Email is required"}
        }else{
            const posrData ={ 
                url : API_ADMIN_URL+'users/forgot-password',
                postData : options,
                headers: {
                    'key':'d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed',
                    'Content-Type': 'application/json',
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return {status : true, message:`Your One Time Password is sent to registered ${email}`}
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
/*********************************************************
 *  This function is use to getProfile api
 *********************************************************/
export const getProfile = async (options,authToken) => {
    try {
      const {  } = options;
  
      const posrData = {
        url: API_ADMIN_URL + 'users/get-profile',
        postData: options,
        headers: {
            authToken: authToken
          }
      };
      const res = await postRequest(posrData);
      if (res) {
        return res; // Corrected this line
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  }; // End of Function
  /*********************************************************
 *  This function is use to editProfile api
 *********************************************************/
export const updateProfile = async (options , authToken) => {
    try {
      const { admin_title, admin_first_name, admin_middle_name,admin_last_name ,admin_phone ,admin_email,admin_address,admin_city,admin_state,admin_country,admin_pincode,admin_type } = options;
  console.log("options",options);
      const posrData = {
        url: API_ADMIN_URL + 'users/edit-profile',
        postData: options,
        headers: {
            authToken: authToken
          }
      };
      const res = await postRequest(posrData);
      if (res) {
        return res; // Corrected this line
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  }; // End of Function
  
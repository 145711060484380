import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import {
  updateCategoryformData,
  getCategoryData,
} from "../Controller/Category";
import AddCategorypopup from "./EditCategoryDatapopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Category.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { imageURL } from "../Config/constant";
import { SketchPicker } from "react-color";

function EditCategoryData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openColorPicker, setOpenColorPicker] = useState(true); // Set to true to open on load

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    category_name: "",
    show: "",
    image: null,
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const handleFileChange1 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));

    setErrors((prevError) => ({
      ...prevError,
      [name]: "",
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.category_name) {
        setErrors((preError) => ({
          ...preError,
          category_name: "Category name is required.",
        }));
      }
      // else if (!formData.color) {
      //   setErrors((preError) => ({
      //     ...preError,
      //     color: "Category color is required.",
      //   }));
      // }
      else {
        setIsSubmitting(true);
        const updateResult = await updateCategoryformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            category_name: "",
            color: "",
            image: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  /*************************************************************
   * Function Name  : getcategorydata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getcategorydata = async () => {
    const options = { id: edit };
    try {
      const result = await getCategoryData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        // Replace _id with id in the response data
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id; // Remove the _id property
        setFormData(modifiedData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const handleColorChange = (color) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      color: color.hex,
    }));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getcategorydata();
  }, []);
  /*************************************************************
          End Function
   *************************************************************/

  const [popUpdeOpen, setPopUpdeOpen] = useState(false);

  const toggleColorPicker = (e) => {
    e.preventDefault();
    setOpenColorPicker(!openColorPicker);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Category </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group">
                        <label htmlFor="category_name">
                          Category Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="category_name"
                          name="category_name"
                          placeholder="Enter Category Name"
                          value={formData.category_name}
                          onChange={(e) => handleInputChange(e)}
                        />
                        {errors?.category_name && (
                          <p style={{ color: "red" }}>
                            {errors?.category_name}
                          </p>
                        )}
                      </div>
                      {/* <div className="form-group">
                        <label htmlFor="color">
                          Category Color<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ display: "inline-block" }}>
                          <div
                            onClick={toggleColorPicker}
                            style={{
                              width: "36px",
                              height: "14px",
                              borderRadius: "2px",
                              backgroundColor: formData.color,
                              cursor: "pointer",
                            }}
                          ></div>
                          {openColorPicker && (
                            <div style={{ position: "absolute", zIndex: "2" }}>
                              <SketchPicker
                                color={formData.color}
                                onChange={handleColorChange}
                              />
                            </div>
                          )}
                        </div>
                        {errors?.color && (
                          <p style={{ color: "red" }}>{errors.color}</p>
                        )}
                      </div> */}

                      {/* <div className="form-group">
                        <label htmlFor="fullname">Image</label>
                        <ImgCrop rotate aspect={636 / 850}>
                          <Upload
                            className="avatar-uploader"
                            name="image"
                            onChange={({ fileList }) =>
                              handleFileChange1(fileList)
                            }
                            listType="picture-card"
                            fileList={null}
                            beforeUpload={() => false}
                            style={{ width: "100%", height: "100%" }}
                          >
                            {formData.image ? (
                              <img
                                src={
                                  typeof formData.image === "string"
                                    ? `${imageURL}${formData.image}`
                                    : URL.createObjectURL(formData.image)
                                }
                                alt="subcategory"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div>
                                <svg
                                  className="icon"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="image"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                  ></path>
                                </svg>
                                <div className="ant-upload-text">Upload</div>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                      </div> */}
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Update"
                          )}
                        </button>
                        <Link to="/categoryList">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCategorypopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCategoryData;
